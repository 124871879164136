.seller {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1300px;
  margin: auto 0;
  padding: 20px 0;
  margin: 0 auto;
  background: #f5f5f5;
  color: #222222;
}
/* 左边菜单栏 */
.seller_tab {
  width: 160px;
  min-height: calc(100vh - 270px);
  background: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
}
.seller_tab_header {
  /* height: 110px; */
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
}
.seller_tab_button {
  width: 100px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #f94a3d;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  margin: 0 auto;
  cursor: pointer;
}
.seller_tab_button:hover {
  opacity: 0.8;
}
.seller_tab_sub {
  padding: 20px 20px 8px;
  border-bottom: 1px solid #ebeef5;
}
.seller_tab_title {
  font-size: 16px;
  margin-bottom: 14px;
}
.seller_sub_title {
  font-size: 14px;
  padding-left: 25px;
  margin-bottom: 14px;
  color: #222222;
}
.seller_title {
  font-size: 16px;
  color: #222222;
}
.seller_sub_title:hover {
  color: #f94a3d;
}
.seller_active_tab {
  color: #f94a3d;
}
/* 右边内容区域 */
.seller_box {
  box-sizing: border-box;
  flex: 1;
  min-height: calc(100vh - 270px);
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 100px;
  margin-left: 20px;
}
/* 发货流程 */
.goods_info_title {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.goods_info_platform .ant-radio-button-wrapper {
  width: 120px;
  text-align: center;
  border-radius: 4px !important;
  margin-right: 50px;
  border-left-width: 1px;
}
.goods_info_platform .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.goods_info_tips {
  padding: 20px;
  background: #f5f5f5;
  font-size: 14px;
  color: #333333;
}
.goods_info_tips > div {
  line-height: 38px;
}
.goods_file .ant-upload.ant-upload-drag {
  width: 360px;
  height: 180px;
}
.goods_file_tips {
  box-sizing: border-box;
  flex: 1;
  height: 178px;
  margin-left: 20px;
  border-radius: 4px;
  background: #f5f5f5;
  padding: 20px;
}
.goods_file_tips > div {
  line-height: 30px;
}
.goods_file_plugin {
  width: 360px;
  height: 180px;
  text-align: center;
}
.goods_file_plugin > img {
  display: block;
  width: 100px;
  height: 100px;
  margin: 12px auto;
}
/* 订单管理 */
.orderlist_button {
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translateX(-50%);
}
.parce_list .ant-table-thead > tr > th,
.parce_list .ant-table-tbody > tr > td {
  padding: 16px 5px;
}
.order_table .ant-table-thead > tr > th,
.order_table .ant-table-tbody > tr > td {
  padding: 16px 10px;
}
.ant-picker-range {
  width: 300px;
}
/* 账户充值 */
.recharge {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebeef5;
}
.recharge_use {
  display: flex;
  align-items: center;
  height: 100px;
  border-radius: 4px;
  background: #f8f8f8;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.recharge_type {
  padding: 40px 20px;
  border-radius: 4px;
  background: #f8f8f8;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.recharge_list {
  padding: 40px 20px;
  border-radius: 4px;
  background: #f8f8f8;
}
/* 常见问题 */
.question {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebeef5;
}
.question > div:first-child {
  font-size: 16px;
  font-weight: 600;
}
.question > div {
  line-height: 40px;
}
/* 推广赚钱 */
.invite_url {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  background: #f8f8f8;
  padding-left: 20px;
  margin: 20px 0;
}
.invite_info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100px;
  font-size: 16px;
  margin-bottom: 20px;
}
.invite_info > span {
  color: #ff143b;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}
.courier_line {
  flex-shrink: 0;
  width: 20px;
  height: 90px;
  margin-left: 12px;
  border-left: 2px solid #d9d9d9;
  position: relative;
}
.courier_line::after {
  content: "";
  display: block;
  background: #d9d9d9;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  right: 13px;
}
/* 售后查件 */
.service_title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 20px;
  margin-bottom: 13px;
  border-bottom: 1px solid #ebeef5;
}
.service_tab {
  margin-top: 10px;
}
.search_form {
  margin-top: 10px;
}
.search_form .top_margin {
  margin-top: 18px;
}
.search_form .flex-start label.label {
  width: 85px;
}
.search_form .flex-start .input_desc {
  width: 50%;
}
.search_form .flex-start .input_email {
  width: 25%;
}
.search_form .flex-start .upload_tip {
  width: 200px;
  font-size: 13px;
}
.search_form .flex-start .ant-upload-picture-card-wrapper {
  width: 100%;
}
.search_form .flex-start .number_test {
  width: 400px;
  height: 200px;
  border: 1px solid #eaeaea;
  line-height: 24px;
  font-size: 14px;
  padding: 10px 15px;
  overflow-y: auto;
  margin-left: 10px;
}
.search_form .flex-start .number_test p {
  color: red;
}
.search_result_modal .ant-modal-body {
  padding: 10px;
}
.line-edit-wrapper {
  width: 55%;
  height: 200px;
  display: flex;
}
.left-bar-wrapper {
  background: #e6e6e6;
  width: 36px;
  height: 100%;
  text-align: left;
  float: left;
}
.rigth-edit-wrapper {
  width: 100%;
  height: 100%;
}
.rigth-edit-wrapper .editArea {
  border: 1px solid #eaeaea;
  outline: none;
  width: 100%;
  height: 100%;
  resize: none;
  background: #fafafa;
  line-height: 24px;
  font-size: 14px;
  float: left;
  padding: 10px 8px;
  color: black;
  font-family: inherit;
  box-sizing: border-box;
}
.left-bar-wrapper .barArea {
  height: 100%;
  width: 100%;
  resize: none;
  outline: none;
  overflow-y: hidden;
  overflow-x: hidden;
  border: 0;
  background: #f7f7f7;
  color: #999;
  line-height: 24px;
  font-size: 14px;
  padding: 11px 4px;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
}
.photo_num {
  position: absolute;
  width: 16px;
  height: 15px;
  line-height: 15px;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 2px;
}
.image-wrapper .ant-modal-body {
  background-color: #6c6c6c;
}
.image-wrapper .ant-carousel .slick-list img {
  max-width: 100%;
  max-height: 600px;
  object-fit: contain;
}
/* 工作台 */
.center_title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 26px;
  display: flex;
  align-items: center;
}
.border-top {
  border-top: 1px solid #ebeef5;
  margin-top: 30px;
  padding-top: 20px;
}
.center_title .title_border {
  margin-right: 6px;
  width: 3px;
  height: 16px;
  border-radius: 3px;
  background-color: #f94a3d;
}
.icon-wrap {
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.icon-wrap:hover {
  background-color: aliceblue;
}
.center_setting .prefix_login {
  width: 40px;
  height: 38px;
}
.center_setting .prefix {
  width: 40px;
  height: 38px;
  background: #e6e6e6;
  text-align: center;
}
.center_setting .ant-input-affix-wrapper {
  padding: 0;
  padding-right: 10px;
}
.center_setting .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 38px;
}
.center_setting .prefix img {
  width: 18px;
  height: 20px;
}
.center_setting .ant-input-affix-wrapper > input.ant-input {
  padding-left: 10px;
}
.center_setting .get_code {
  background: #f94a3d;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.center_setting .ant-input-group-addon {
  background: #f94a3d;
  border: 1px solid #f94a3d;
  border-radius: 0;
}
.center_setting .btn {
  width: 120px;
  height: 36px;
  background: #f94a3d;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #fefefe;
  margin-top: 10px;
  text-align: center;
}
.image-wrapper .carousel-left,
.image-wrapper .carousel-right {
  background-color: #000;
  width: 40px;
  height: 40px;
  opacity: 0.28;
  cursor: pointer;
  position: absolute;
  top: 45%;
  z-index: 2;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-wrapper .carousel-left {
  left: 0;
}
.image-wrapper .carousel-right {
  right: 0;
}
.image-wrapper .carousel-left:hover,
.image-wrapper .carousel-right:hover {
  opacity: 0.4;
  cursor: pointer;
}
/* 实名认证 */
.auth-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 16px;
}
.auth-title-sub {
  color: #f94a3d;
  line-height: 14px;
  margin-bottom: 20px;
}
.auth-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #EBEBEB;
}
.auth-item .center {
  margin-left: 30px;
}
.auth-item .center p {
  margin-bottom: 10px !important;
}
.auth-item .right button {
  width: 88px;
  height: 34px;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  cursor: pointer;
}
.auth-item .right button:hover {
  color: #FFFFFF;
  background: #f94a3d;
}
.auth-form {
  margin-top: 40px;
  padding-left: 20px;
}
.auth-form .label {
  width: 90px;
  margin-right: 10px;
}
.auth-form .flex-start {
  margin-bottom: 22px;
}
.auth-form .input,
.auth-form .cascader {
  width: 260px;
}
.auth-form .mobile button {
  margin-left: 10px;
  border-radius: 16px;
}
.auth-form .flex-start .shopTips {
  color: #A0A6B4;
  margin-left: 10px;
}
.auth-form .getCode {
  margin-top: 50px;
  margin-left: 110px;
}
.auth-form .avatar-uploader .ant-upload-list-item {
  margin-top: 0;
}
.getCode button {
  width: 160px;
  height: 38px;
}
.introduction-wrapper .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.introduction-wrapper .qrCode {
  text-align: center;
  margin-top: 28px;
}
.qrCode .img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background: #F8F9FA;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.success-icon {
  padding-top: 20px;
  text-align: center;
}
.success-icon img {
  width: 50px;
  height: 62px;
}
.success-desc {
  margin-top: 28px;
  text-align: center;
  font-weight: 600;
}
.success-btn {
  margin-top: 60px;
  text-align: center;
}
.success-btn .btn {
  padding: 0 60px !important;
}
.rename-item {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 15px;
}
.rename-item label {
  display: inline-block;
  width: 80px;
  text-align: right;
  color: #6c6c6c;
  font-weight: 400;
}

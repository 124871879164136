// https://4x-ant-design.antgroup.com/docs/react/customize-theme-cn

html {
  --antd-wave-shadow-color: #f94a3d;
}

.ant-btn-primary {
  background: #f94a3d;
  border-color: #f94a3d;
}

.ant-btn-primary:hover {
  background: #f94a3d;
  border-color: #f94a3d;
  opacity: .8;
}

.ant-input:hover {
  border-color: #f94a3d;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #f94a3d;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #f94a3d;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover
{
  background: #f94a3d;
  border-color: #f94a3d;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #f94a3d;
  border-color: #f94a3d;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #f94a3d;
}

.ant-radio-button-wrapper:hover {
  color: #f94a3d;
}
.ant-input:focus, .ant-input-focused {
  border-color: #f94a3d;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #f94a3d;
}
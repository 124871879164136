/* 登录 */
.login {
  width: 100%;
  min-height: calc(100vh - 230px);
  background: #fff;
}
.login_bg {
  width: 100%;
  height: 100%;
}
.log_box {
  position: absolute;
  top: 0;
  width: 1200px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 132px;
}
.log_content {
  margin: 125px 70px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.log_center {
  margin: 0 auto;
  color: #ff3333;
  border: 1px solid #ff3333;
  border-radius: 2px;
  width: 80px;
  background: #ffdee5;
  height: 24px;
  cursor: pointer;
  /* line-height: 24px; */
}
.login_box1 {
  padding: 30px;
  background: #ffffff;
}
.login_box1 .login_text {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
}
.login_box1 .ant-input-affix-wrapper:hover {
  border-color: #f94a3d;
}
.login_box1 .ant-input:hover {
  border-color: #f94a3d;
}
.login_box1 .ant-input-affix-wrapper .ant-input-prefix {
  left: 1px;
}
.login_box1 .prefix_login {
  width: 40px;
  height: 38px;
}
.login_box1 .prefix {
  width: 40px;
  height: 38px;
  background: #e6e6e6;
  text-align: center;
}
.login_box1 .ant-input-affix-wrapper {
  padding: 0;
  padding-right: 10px;
}
.login_box1 .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 38px;
}
.login_box1 .prefix img {
  width: 18px;
  height: 20px;
}
.login_box1 .ant-input-affix-wrapper > input.ant-input {
  padding-left: 10px;
}
.login_box1 .btn {
  width: 290px;
  height: 40px;
  line-height: 40px;
  background: #f94a3d;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #fefefe;
  margin-top: 30px;
  text-align: center;
}
.login_box1 .ant-btn {
  padding: 0 15px;
}
.login_box1 .ant-btn:hover,
.ant-btn:focus {
  border-color: #f94a3d;
  color: #f94a3d;
}
.login_box1 .get_code {
  background: #f94a3d;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.login_box1 .ant-input-group-addon {
  background: #f94a3d;
  border: 1px solid #f94a3d;
  border-radius: 0;
}

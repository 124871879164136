@charset "utf-8";
@import "~antd/dist/antd.less";
@import "./antd.less";
@import "./normalize.css";
@import "./home.css";
@import "./login.css";
@import "./seller.css";

/* flex布局 */
.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-space-colstart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-coloum-default {
  display: flex;
  flex-direction: column;
}

.flex-column-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* loading */
.su-loader {
  position: relative;
  width: 25em;
  height: 25em;
  transform: rotate(165deg);
}

.su-loader:before,
.su-loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 5em;
  height: 5em;
  border-radius: 2.5em;
  transform: translate(-50%, -50%);
}

.su-loader:before {
  -webkit-animation: before 2s infinite;
  animation: before 2s infinite;
}

.su-loader:after {
  -webkit-animation: after 2s infinite;
  animation: after 2s infinite;
}

@-webkit-keyframes before {
  0% {
    width: 5em;
    box-shadow: 10em -5em rgba(225, 20, 98, 0.75),
      -10em 5em rgba(111, 202, 220, 0.75);
  }

  35% {
    width: 25em;
    box-shadow: 0 -5em rgba(225, 20, 98, 0.75), 0 5em rgba(111, 202, 220, 0.75);
  }

  70% {
    width: 5em;
    box-shadow: -10em -5em rgba(225, 20, 98, 0.75),
      10em 5em rgba(111, 202, 220, 0.75);
  }

  100% {
    box-shadow: 10em -5em rgba(225, 20, 98, 0.75),
      -10em 5em rgba(111, 202, 220, 0.75);
  }
}

@keyframes before {
  0% {
    width: 5em;
    box-shadow: 10em -5em rgba(225, 20, 98, 0.75),
      -10em 5em rgba(111, 202, 220, 0.75);
  }

  35% {
    width: 25em;
    box-shadow: 0 -5em rgba(225, 20, 98, 0.75), 0 5em rgba(111, 202, 220, 0.75);
  }

  70% {
    width: 5em;
    box-shadow: -10em -5em rgba(225, 20, 98, 0.75),
      10em 5em rgba(111, 202, 220, 0.75);
  }

  100% {
    box-shadow: 10em -5em rgba(225, 20, 98, 0.75),
      -10em 5em rgba(111, 202, 220, 0.75);
  }
}

@-webkit-keyframes after {
  0% {
    height: 5em;
    box-shadow: 5em 10em rgba(61, 184, 143, 0.75),
      -5em -10em rgba(233, 169, 32, 0.75);
  }

  35% {
    height: 25em;
    box-shadow: 5em 0 rgba(61, 184, 143, 0.75), -5em 0 rgba(233, 169, 32, 0.75);
  }

  70% {
    height: 5em;
    box-shadow: 5em -10em rgba(61, 184, 143, 0.75),
      -5em 10em rgba(233, 169, 32, 0.75);
  }

  100% {
    box-shadow: 5em 10em rgba(61, 184, 143, 0.75),
      -5em -10em rgba(233, 169, 32, 0.75);
  }
}

@keyframes after {
  0% {
    height: 5em;
    box-shadow: 5em 10em rgba(61, 184, 143, 0.75),
      -5em -10em rgba(233, 169, 32, 0.75);
  }

  35% {
    height: 25em;
    box-shadow: 5em 0 rgba(61, 184, 143, 0.75), -5em 0 rgba(233, 169, 32, 0.75);
  }

  70% {
    height: 5em;
    box-shadow: 5em -10em rgba(61, 184, 143, 0.75),
      -5em 10em rgba(233, 169, 32, 0.75);
  }

  100% {
    box-shadow: 5em 10em rgba(61, 184, 143, 0.75),
      -5em -10em rgba(233, 169, 32, 0.75);
  }
}

html,
body {
  height: 100%;
}

.su-loader {
  position: absolute;
  top: calc(50% - 12.5em);
  left: calc(50% - 12.5em);
}

/* 公共头部 */
.box {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.common_head {
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  background: #f5f5f5;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}

.common_head ul {
  margin: 0;
}

.common_head ul li {
  padding: 0 20px;
}

.common_head ul li img {
  width: 12px;
  height: 12px;
  margin-top: -3px;
  margin-right: 10px;
}

.common_head ul > :first-child {
  padding: 0 10px 0 0;
}

.common_head ul > :last-child {
  padding: 0 0 0 10px;
}

.collect {
  position: relative;
  overflow: hidden;
}

.collect_show {
  background: #fff;
  display: none;
  position: absolute;
  top: 34px;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 3px;
  line-height: 1;
}

.collect_show:before {
  content: "";
  top: -12px;
  left: 40px;
  position: absolute;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-top: 6px solid transparent;
  border-right: 6px solid transparent;
}

.common_head li:hover {
  cursor: pointer;
  color: #f94a3d;
}

#common_log {
  z-index: 1;
  background: transparent;
  position: relative;
  padding: 0 20px;
}

#common_log:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1px;
  left: 0;
  z-index: -1;
  background: #ffffff;
  transform: skew(-30deg);
  padding: 0 20px;
}

#common_log:hover + li {
  visibility: hidden;
}

.logout_show {
  background: #fff;
  display: none;
  position: absolute;
  top: 34px;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 3px;
  line-height: 1;
  color: #333;
  z-index: 111;
}

.logout_show p {
  margin: 0;
  padding: 6px 5px;
}

.logout_show p:hover {
  color: #f94a3d;
}

.logout_show:before {
  content: "";
  top: -12px;
  left: 40px;
  position: absolute;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-top: 6px solid transparent;
  border-right: 6px solid transparent;
}

#is_login {
  position: relative;
}

#is_login:hover .logout_show,
.logout_show:hover {
  display: block;
}

#collect:hover {
  color: #333;
}

#collect:hover .collect_show {
  display: block;
}

b {
  color: #ccc;
  font-weight: normal;
}

.home_header_top {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  background: url('https://images.weserv.nl/?url=https://dfcdn.shuidilipin.com/source/daifa/topbanner.png') center center no-repeat;
  background-size: 100% 100%;
  position: relative;
  cursor: pointer;
}

.home_header_top .anticon {
  position: absolute;
  font-size: 16px;
  color: white;
  right: 25px;
  top: 17px;
  cursor: pointer;
}

.home_header {
  width: 1200px;
  height: 60px;
  margin: 0 auto;
  color: #333333;
}

.home_header_name img {
  display: block;
  width: 224px;
  /* margin-right: 120px; */
}

.home_header_tab {
  font-size: 14px;
  padding: 6px 0;
  margin-right: 40px;
  cursor: pointer;
}

.home_header_tab:hover {
  color: #f94a3d;
}

.active_tab {
  color: #f94a3d;
  border-bottom: 2px solid #f94a3d;
}

.home_header_tab_logout {
  display: inline-block;
  width: 49px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #f94a3d;
  margin-left: 10px;
  cursor: pointer;
}

.home_header_tab_logout:hover {
  color: #f94a3d;
}

/* 公共底部 */
.common_footer {
  min-width: 1200px;
  background: #fff;
  /*border-top: 2px solid rgba(245, 49, 75, 1);*/
  text-align: center;
  padding-top: 10px;
  /* margin-top: 50px; */
}
.common_footer .ant-divider-horizontal {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
}
.common_footer .ant-divider-horizontal::before, .common_footer .ant-divider-horizontal::after{
  border-top: 1px solid #F5F7FA;
}
.common_footer .ant-divider-horizontal .ant-divider-inner-text{
  color: #f94a3d;
}

.common_footer .footer_desc {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #7A8399;
  padding-top: 15px;
  padding-bottom: 20px;
  /*display: flex;*/
  /*justify-content: space-between;*/
  width: 630px;
  margin: 0 auto;
}

.common_footer .footer_desc .index {
  width: 16px;
  height: 16px;
  background-color: #F5F7FA;
  border-radius: 50%;
  margin-right: 6px;
}

.common_footer .footer_href {
  padding: 4px 0;
  background-color: #F5F6FA;
}

.common_footer .footer_href p {
  padding: 4px 0;
  background-color: #F5F6FA;
  color: #7A8399 !important;
}


.common_footer .footer_href a {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #7A8399 !important;
}

.common_footer .footer_href a:hover {
  color: rgba(245, 49, 75, 1) !important;
}


/* 首次进入弹窗 */
.modal_first {
  width: 300px !important;
}

.modal_first .ant-modal-content {
  width: 300px;
  height: 400px;
  background: #f94a3d;
}

.modal_first .ant-modal-body {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.modal_first .ant-modal-close-x {
  color: #ffffff;
}

.modal_first .modal_code {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 252px;
  height: 252px;
  background: #ffffff;
  margin: 30px auto 16px;
}

.modal_first .modal_code > img {
  width: 240px;
  height: 240px;
}

.modalNotice .notice_wrapper table tr td, .notice_wrapper table tr td  {
  padding: 4px 10px;
}

.background_color {
  width: 100%;
  height: 770px;
  background: linear-gradient(#f94a3d, #f94a3d);
}

.fix_icon_wrapper {
  position: fixed;
  bottom: 10%;
  right: 3%;
  height: 235px;
}
.fix_icon_show_wrapper {
  position: fixed;
  bottom: calc(10% + 190px);
  right: 0;
}
.fix_icon_show_wrapper .show_icon {
  width: 30px;
  height: 35px;
  line-height: 35px;
  background-image: linear-gradient(180deg, #f94a3d 0%, #f94a3d 100%);
  border-radius: 6px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.fix_icon_wrapper .icon_bg .hide{
  color: #fff;
  cursor: pointer;
}
.fix_icon_wrapper .icon_bg {
  /* height: 71px; */
  height: 31px;
  width: 45px;
  background-image: linear-gradient(180deg, #f94a3d 0%, #f94a3d 100%);
  border-radius: 6px;
  text-align: center;
  padding: 6px 0;
  margin: auto;
}
.fix_icon_wrapper .icon_bg .text{
  color: #fff;
  line-height: 18px;
}
.fix_icon_wrapper .icon_bg .line{
  margin: 0 4px;
  height: 1px;
  background-color: #fff;
}
.fix_icon_wrapper .icon_contact {
  width: 45px;
  background-image: linear-gradient(180deg, #f94a3d 0%, #f94a3d 100%);
  border-radius: 6px;
  text-align: center;
  margin: auto;
}
.fix_icon_wrapper .icon_contact .line {
  margin: 0 4px;
  height: 1px;
  background-color: #fff;
}
.fix_icon_wrapper .kf_icon {
  width: 45px;
  height: 45px;
}
.fix_icon_wrapper .kf_wechat {
  width: 45px;
  height: 45px;
}

.fix_icon_wrapper .kefu_online img{
  width: 60px;
  margin-bottom: 6px;
  cursor: pointer;
}

.ant-popover {
  max-width: 300px;
}

/*帮助中心*/
.help-center {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1300px;
  margin: auto 0;
  padding: 20px 0;
  margin: 0 auto;
  background: #f5f5f5;
  color: #222222;
}

/* 左边菜单栏 */
.help_tab {
  width: 160px;
  min-height: calc(100vh - 170px);
  background: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
}

.tab_title {
  height: 60px;
  padding-top: 15px;
  font-size: 20px;
  font-weight: 600;
  padding-left: 24px;
  border-bottom: 1px solid #ebeef5;
}

.help_tab_sub {
  padding-top: 20px;
}

.help_tab_sub .tab_sub_title {
  height: 50px;
  line-height: 50px;
  padding-left: 24px;
  font-size: 16px;
  cursor: pointer;
}

.help_tab_sub .help_active_tab {
  background: #f94a3d;
  color: #ffffff;
}

/* 右边内容区域 */
.help_box {
  box-sizing: border-box;
  flex: 1 1;
  min-height: calc(100vh - 170px);
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-left: 20px;
}
.help_box .img_box {
  height: 100%;
}

.ant-tabs .ant-tabs-nav {
  margin-bottom: 15px;
}
.ant-tabs .ant-tabs-nav::before {
  border-bottom: none;
}
.ant-tabs .ant-tabs-tab {
  padding: 0px 0px 6px 0px;
}

@primary-color: #f94a3d;@text-color: #333333;@link-color: #f94a3d;
.home {
  min-height: calc(100vh - 230px);
  background: #f5f5f5;
  color: #000000;
}

.home_banner {
  width: 100%;
  height: 260px;
  background: url("https://dfcdn.shuidilipin.com/source/daifa/banner5.png") 50%
    50% no-repeat;
  cursor: pointer;
  margin-bottom: 10px;
}

.home_banner1 {
  width: 100%;
  /* height: 260px; */
  cursor: pointer;
  margin-bottom: 10px;
}
.home_banner1 img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.home_banner_model {
  width: 100%;
  height: 60px;
  background: url("https://dfcdn.shuidilipin.com/source/daifa/banner_model.png")
    50% 50% no-repeat;
  cursor: pointer;
}

.home_box {
  box-sizing: border-box;
  width: 1200px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  padding: 0 24px;
  z-index: 11;
}

.home_sort,
.home_sort_other {
  font-size: 12px;
  color: #333333;
  margin-right: 50px;
  cursor: pointer;
}

.home_sort_active {
  color: #f94a3d;
  font-weight: 600;
}

.home_sort:hover {
  color: #f94a3d;
  font-weight: 600;
}

.home_sort_end {
  background: #f5f5f5;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}
.home_sort_end_left {
  display: flex;
}
.home_sort_end .home_sort {
  padding: 0 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  margin-right: 10px;
}
.home_sort_end .home_sort_active {
  color: #fff;
  background-color: #f94a3d;
}


.home_sort_end .price-filter {
  align-self: center;
  height: 22px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  font-size: 12px;
  color: #b2b7bd
}

.home_sort_end .price-filter .ant-input-number {
  width: 68px;
  text-align: left
}

.home_sort_end .price-filter .ant-btn {
  margin-left: 5px;
}

.home_sort_end .price-filter .heng {
  margin: 0 4px
}


.home_goods {
  margin-bottom: 20px;
  cursor: pointer;
  width: 220px;
  height: 360px;
  background: #ffffff;
  position: relative;
}

.home_goods_img {
  width: 220px;
  height: 220px;
  overflow: hidden;
}

.home_goods_img > img {
  display: block;
  width: 220px;
  height: 220px;
  transition: all 0.3s ease 0s;
}

.home_goods_img > img:hover {
  transform: scale(1.2);
}

.home_goods_info {
  box-sizing: border-box;
  width: 220px;
  height: 140px;
  padding: 10px;
}

.home_goods_title {
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home_goods_baoyou {
  font-size: 12px;
  background-color: #f94a3d;
  color: #fff;
  padding: 1px 4px;
  border: 1px solid #f94a3d;
  border-radius: 4px;
  margin-top: 3px;
}

.home_goods_local {
  height: 26px;
  width: 66px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  color: #666666;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 2px;
}

.home_goods_hover {
  width: 220px;
  height: 360px;
  /* background: #ffffff; */
  color: #333333;
  position: absolute;
  top: 0;
  left: 0;
}

.home_goods_buy {
  width: 200px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #f94a3d;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}

.home_goods_buy:hover {
  opacity: .96;
}

.home_goods_hover_info {
  height: 122px;
  padding: 6px 0;
  margin-bottom: 4px;
}

.home_goods_hover_title {
  padding-left: 12px;
  font-size: 16px;
  font-weight: 600;
  writing-mode: vertical-lr;
}

.home_goods_hover_line {
  margin: 0 10px;
  width: 1px;
  height: 110px;
  background: #d9d9d9;
}

.home_goods_hover_detail > div {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 新版首页 */
.home_new_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.home_new_tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
}

.home_new_tab img {
  display: block;
  width: 130px;
  margin-right: 120px;
}

.home_new_tab_item {
  font-size: 14px;
  padding: 6px 0;
  margin-right: 80px;
  cursor: pointer;
}

.home_new_hot {
  margin: 34px auto;
}

.home_new_hot > img {
  height: 100%;
}

.home_new_hot_box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 968px;
  height: 750px;
  background: #ffffff;
}

/* 新首页 */
.new_home_goods {
  box-sizing: border-box;
  width: 1200px;
  height: 220px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  /* cursor: pointer; */
}

.new_home_goods_flex {
  box-sizing: border-box;
  width: 230px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.new_home_goods > div > img {
  display: block;
  width: 180px;
  height: 180px;
  margin-right: 10px;
}

.new_home_goods_flex > div > div > img {
  display: block;
  width: 230px;
  height: 230px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.new_home_goods_flex .ant-btn-primary {
  background-color: #f94a3d;
  border-color: #f94a3d;
}

.new_home_goods_flex .ant-btn-primary:hover {
  background-color: #f94a3d;
  border-color: #f94a3d;
  opacity: 0.8;
}

.new_home_goods_icon {
  width: 60px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px 0px 4px 0px;
  position: absolute;
  top: 0;
  left: 0;
}

.new_home_goods_info {
   position: relative;
   width: 200px;
   height: 180px;
 }

.new_home_goods_info_flex {
  position: relative;
  width: 230px;
  /* height: 167px; */
  height: 120px;
  padding: 10px;
}

.new_home_goods_title {
  font-size: 16px;
  font-weight: 600;
  color: #262a33;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.new_home_goods_title_flex {
  color: #262a33;
  width: 190px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.new_home_goods_local {
  width: 58px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #262a33;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  font-size: 12px;
}

.new_home_goods_local_flex {
  display: inline-block;
  padding: 2px 4px;
  text-align: center;
  line-height: 1;
  color: #262a33;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  font-size: 12px;
}

/* 超时赔付弹窗 */
.modal_paid {
  width: 900px !important;
  height: 564px;
  background: #f2f4f7;
  border-radius: 10px;
}

.modal_paid .ant-modal-content {
  height: 564px;
  background: #f2f4f7;
  border-radius: 10px;
}

.modal_paid .ant-modal-body {
  padding: 0;
}

.modal_paid_title {
  box-sizing: border-box;
  width: 900px;
  height: 127px;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 24px 0;
  text-align: center;
  font-weight: 600;
}

.modal_paid_box {
  font-size: 14px;
  color: #1a2133;
  padding: 0 20px;
  margin-top: 10px;
}

.modal_paid_content {
  box-sizing: border-box;
  width: 860px;
  height: 112px;
  background: #ffffff;
  color: #7a8399;
  border-radius: 6px;
  padding: 14px 10px;
  margin-top: 10px;
}

.modal_paid_text {
  margin-top: 10px;
}

.modal_paid_text::before {
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  background: #99d5ff;
  border-radius: 50%;
  margin-right: 6px;
  margin-bottom: 2px;
}

.home_baoyou {
  padding: 2px 2px;
  background: #ff3333;
  color: #ffffff;
  font-size: 12px;
  margin-right: 10px;
}

.home_collect {
  height: 24px;
  border-radius: 12px 0 0 12px;
  background: #ff3333;
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  right: -20px;
  top: 40px;
  overflow: hidden;
  -webkit-transition: width 0.1s;
  transition: width 0.1s;
}

/* 新版筛选条件 */
.home_new_box {
  box-sizing: border-box;
  width: 1200px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  z-index: 11;
  font-size: 12px;
}

.home_new_box > div:not(:last-child) {
  display: flex;
  justify-content: flex-start;
  padding: 6px 24px;
  border-bottom: 1px dotted #d2d2d2;
}

.home_new_box > div:not(:last-child) > div:first-child {
  width: 60px;
  flex-shrink: 0;
}

.home_new_box > div:not(:last-child) > div:last-child {
  flex: 1;
  /*margin-left: 20px;*/
}


.home_new_box .filter-condition-comp{
	display: flex;
	line-height: 30px;
	color: #333;
	align-items: center;
	padding-left: 10px;
	position: relative;
}

.home_new_box .filter-condition-comp .filter-title img{
	width: 40px;
}


.home_new_box_item {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin: 3px 0 3px 24px;
  padding: 0 6px;
  cursor: pointer;
}

.home_new_box_item:hover {
  color: #f94a3d;
}

.home_new_box_active {
  background: #fee4e2;
  color: #f94a3d;
  border-radius: 2px;
}


.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 12px !important;
}

.home-header {
  width: 100%;
  height: 420px;
/*  padding-top: 60px;*/
  background: linear-gradient(180deg, rgba(255, 156, 128, .15), hsla(0, 0%, 100%, .15));
}

.home-header_wrapper {
  width: 1320px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  top: 20px;
}
.home-header_left,
.home-header_right {
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.home-header_left {
  width: 962px;
  height: 360px;
}

.home-header_left img {
  width: 962px;
  height: 360px;
}
.home-header_left .home-header_btn {
  position: absolute;
  top: 235px;
  left: 50px;
  width: 128px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #f94a3d;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 21px;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(193, 49, 31, .47);
}
.home-header_left .home-header_btn:hover {
  opacity: .88;
}
.home-header_right {
  width: 338px;
  height: 360px;
}
.home-header_right:before {
  content: "";
  display: inline-block;
  width: 300px;
  height: 6px;
  background: #f94a3d;
  border-radius: 4px;
  position: absolute;
  top: -3px;
  left: 50%;
  margin-left: -150px
}

.home-unLogin {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.home-unLogin_header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative
}

.home-unLogin_header:before {
  content: "";
  display: inline-block;
  width: 276px;
  border: 1px dashed #000;
  opacity: .05;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -138px
}

.home-unLogin_header .home-login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center
}

.home-unLogin_header .home-login-wrapper .login-btn {
  color: #f94a3d;
  cursor: pointer;
  border-bottom: 1px solid #f94a3d
}

.home-unLogin_header .head-portrait-wrapper {
  border-width: 2px;
  border-color: #fff;
  border-style: solid;
  border-radius: 50%;
  background-image: linear-gradient(143deg,#ffe4e2,#ffcfcb);
  box-shadow: 0 3px 19px 0 rgba(249,74,61,.31);
  position: relative;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-bottom: 20px
}

.home-unLogin_header .head-portrait-wrapper .head-portrait {
  border-radius: 50%;
  position: absolute;
  left: 50%;
  background-color: #f94a3d;
  background-image: linear-gradient(143deg,#ff9f98,#f94a3d)
}

.home-unLogin_header .head-portrait-wrapper .head-portrait_s {
  width: 24px;
  height: 24px;
  top: 15px;
  margin-left: -12px
}

.home-unLogin_header .head-portrait-wrapper .head-portrait_l {
  width: 50px;
  height: 50px;
  top: 40px;
  margin-left: -25px
}

.home-order-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  text-align: center
}

.home-order-data .order-data-item {
  cursor: pointer
}

.home-order-data .el-divider--vertical {
  width: 1px;
  height: 20px;
  background: #eef2fb
}

.home-order-data .num {
  font-size: 20px;
  font-weight: 700;
  color: #1c1f21;
  margin-bottom: 13px
}

.home-order-data .label {
  font-size: 14px;
  font-weight: 500;
  color: #9199a1
}

.home-advantage-wrapper {
  width: 1320px;
  margin: 0 auto;
  height: 91px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center
}

.home-advantage-wrapper .vertical-divider {
  height: 44px;
  width: 1px;
  background-color: #eef2fb
}

.home-advantage-wrapper .home-advantage-item {
  display: flex;
  align-items: center
}

.home-advantage-wrapper .home-advantage-item img {
  display: flex;
  align-items: center;
  width: 58px;
}

.home-advantage-wrapper .home-advantage-item.is-user {
  cursor: pointer
}

.home-advantage-wrapper .home-advantage-item .home-advantage-icon-wrapper {
  position: relative
}

.home-advantage-wrapper .home-advantage-item .home-advantage-text-wrapper {
  margin-left: 24px;
  font-weight: 500
}

.home-advantage-wrapper .home-advantage-item .home-advantage-text-wrapper>:first-child {
  font-size: 16px;
  color: #1c1f21;
  margin-bottom: 15px
}

.home-advantage-wrapper .home-advantage-item .home-advantage-text-wrapper>:last-child {
  font-size: 12px;
  color: #9199a1
}

.home-advantage-wrapper .home-advantage-item .home-advantage-text-wrapper>:last-child .dat-shu {
  margin: 0 4px
}

@primary-color: #f94a3d;@text-color: #333333;@link-color: #f94a3d;